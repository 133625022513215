<!--必填项分组-->
<template>
 <div class="m-grid p-task-allocation">
    <section class="m-grid-search">
      <el-input
        class="m-grid-input"
        v-model="searchData.GroupName"
        placeholder="搜索"
                clearable
      ></el-input>
      <el-button class="m-grid-btn" type="primary" @click="onSearch()"
        >查询</el-button
      >
      <el-button class="m-grid-btn" type="primary" @click="onAdd" v-can="'config_common:add'"
        >新增</el-button
      >
    </section>
    <section class="m-grid-list">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        headerHeight="35"
        :onPageChanged="onSearch"
        :page-index="pagination.page"
        :page-size="pagination.size"
        :selection="false"
        :total-count="pagination.total"
        type="base"
      >
      </grid>
    </section>
<!-- 
      <requiredFieldsGroup-edit-view
      ref="requiredFieldsGroupEditView"
      @onEditConfig="onEditConfig"
    ></requiredFieldsGroup-edit-view>
     -->
  </div>
</template>

<script>
import {columns} from "./modules/requiredFieldsGroup/config";
import {GetrequiredFieldsGroup,DelscoreGroupConfig} from "@/api/config";


export default {
  name: "requiredFieldsGroup",
  components: {

  },
  data() {
    return {
     columns: columns,
      itemSource: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },
      searchData: {
        GroupName:""
      },
      isEdit: false,
    };
  },
  methods: {
    async onSearch(page = 1) {
      const { pagination,searchData } = this;

       const sendData = {
        ...searchData,
        Page: page,
        Limit: pagination.size
      }
      const data = await GetrequiredFieldsGroup(sendData); // 请求接口
      console.log(data)
      pagination.page = data.Page
      pagination.total = data.Total
      this.itemSource = data.Items;
    },
    // 新增
    onAdd() {
      this.$router.push({
        name: "requiredFieldsGroupEditView",
      });
    },
     // 初始化表格
    initColumns() {
      const self = this;
      const OriginColumn = columns.find((item) => item.field === "operation");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      this.$refs.list.changeColumns(columns);
    },

    setButtons() {
      let buttons = [];
      let self = this;
      
      const deleteBtn = {
        text: "删除",
        click(params) {
          self.onDelete(params.data);
        }
      };
      
      
      buttons.push(deleteBtn);

      return buttons;
    },
    async onDelete(data) {

      this.$confirm("此操作将输出该属性, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {

          console.log(data)
          const requestData={
            SId:data.ID
          }
          await DelscoreGroupConfig(requestData);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          await this.onSearch();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },

  created() {
   this.onSearch();
  },
  mounted() {
    this.initColumns()
  },
  watch: {},
};
</script>


