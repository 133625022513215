// 标签列头配置
const columns = [
    {
      field: 'rowIndex',
      headerName: '序号',
      width: 130,
      cellClass: 't-c',
        valueFormatter: ({ node: { rowIndex } }) => {
            return rowIndex + 1;
        }
    },
    {
      field: 'GroupName',
      headerName: '必填项组名',
      editable: false,
      width: 235,
      cellClass: 't-c',
    },
    {
      field: 'ItemName',
      headerName: '必填项属性名',
      editable: false,
      width: 235,
      cellClass: 't-c',
    },
    {
      field: 'CreatedTime',
      headerName: '创建时间',
      editable: false,
      width: 235,
      cellClass: 't-c',
    },
    {
        field: 'CreatedEmpName',
        headerName: '创建人',
        editable: false,
        width: 235,
        cellClass: 't-c',
      },
    {
        field: 'UpdatedTime',
        headerName: '更改时间',
        editable: false,
        cellClass: 't-c',
      },
    {
      field: 'UpdatedEmpName',
      headerName: '更改人',
      editable: false,
      cellClass: 't-c',
    },
    {
        field: 'IsActive',
        headerName: '是否启用',
        editable: false,
        cellClass: 't-c',
        valueFormatter: ({ data: { IsActive } }) => {
          // 分配时间段.  周: week, 月：month， 季度: season
          let ret = ''
          switch (IsActive){
            case 2:
              ret = '是'
              break;
            case 1:
              ret = '否'
              break;
          }
          return ret;
        }
      },
    {
      field: 'operation',
      headerName: '操作',
      editable: false,
      cellClass: 't-c',
      cellRendererFramework: 'GridCellButtonRender',
    }
  ]
  

  
  export {
    columns
  }
  